/* eslint-disable */
const React = require('react');
const PropTypes = require('prop-types');
const Page = require('nordic/page');
const Head = require('nordic/head');
const Style = require('nordic/style');
const Image = require('nordic/image');
const Sites = require('../../utils/sites/sites.utils')
const SiteOption = require('../../components/SiteOption/SiteOption');
const Dropdown = require('@andes/dropdown');
const { DropdownItem } = Dropdown;
const Button = require('@andes/button');
const injectI18n = require('nordic/i18n/injectI18n');
const Script = require('nordic/script');


/**
 * View Component
 */
const View = (props) => {
  const { i18n, translations, data, platformID } = props;
  const preloadedState = {
    i18n,
    translations,
    platformID,
    data,
  };

  const listSites= Sites.getListSites(platformID).map((country) => ({
    value: country.id,
    domain: country.domain,
    label: <SiteOption data={country} />,
    name: country.name,
    data
  }))
  const namePage='crossite';

  icon= `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.9/${platformID === 'MP' ?'mercadopago':'mercadolibre'}/favicon.svg` 
  logo= platformID === 'MP' ?'https://mercadolibre.com/org-img/mkt/email-mkt-assets/davinci/2x/ic_logomp_codo_a_codo@2x.png':'https://http2.mlstatic.com/frontend-assets/homes-palpatine/logo_homecom_v2.png'
  
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState(null);

  const handleSelectCountry = ( _ , selectedValueEvent) => {
    if (!selectedValueEvent) return;

    const item = listSites.find(site => site.value === selectedValueEvent);
    if(item){
      let domainToRedirect =  `https://auth.${item.domain}${item.data}`
      setSelectedCountry(domainToRedirect);
      setSelectedValue(selectedValueEvent);
    }
  };

  const handleButtonClick = () => {
    if (selectedCountry) {
      window.location.href = selectedCountry;
    }
  };

  return (
    <Page
      name={namePage}
      className={`${namePage} ${platformID.toLowerCase()}`}
      state={preloadedState}
    >
      <Head>
        <title> Crossite Page </title>
        <link rel="shortcut icon" href={icon}/>
        <Style href="crossite.css" />
        <Script src="vendor.js" />
      </Head>
      <div className="container-align-items">
        <div className={`contenedor-crossite contenedor-ml`}>
          <div className = "content">
            <div className="align-item">
              <Image  className={`img-logo ${platformID.toLowerCase()}-logo`} src={logo}></Image>
            </div>
            <div className="align-item">
              <div className="container-dropdown-list">
                <Dropdown
                  type="form"
                  label={i18n.gettext('Seleccione el país')}
                  placeholder={i18n.gettext('Seleccione el país')}
                  value={selectedValue}
                  onChange={handleSelectCountry}
                  >
                  {listSites.map(item => 
                    <DropdownItem
                      className={`li-${item.value}`}
                      key={`li-${item.value}`}
                      value={item.value}
                      title={item.name}
                    />
                  )}
                </Dropdown>
                <Button className="btn-confirmar" onClick={handleButtonClick} hierarchy="loud" disabled={!selectedCountry} >{i18n.gettext('Confirmar')}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

View.propTypes = {
  imagesPrefix: PropTypes.string,
};

View.defaultProps = {
  imagesPrefix: '/',
  platformID : 'ML',
};

/**
 * Inject i18n context as props into View.
 */
module.exports = injectI18n(View);