const Sites = require('./sites.json');

class SitesUtils {
  static getURLWithSite(baseURL, originalData, countryID, platformID) {
    const domain = this.getDomainsSite(Sites.listSites.find(country => country.id === countryID), platformID);
    return `${baseURL}${domain.domain}${originalData}`;
  }

  static getListSites(platformID) {
    const listSites = Sites.listSites.map((country) => this.getDomainsSite(country, platformID));
    return listSites;
  }

  static getDomainsSite(country, platformID) {
    const ML = 'mercadolibre';
    const MP = 'mercadopago';
    const domainML = country.domainML ?? `${ML}.${country.domain}`;
    const domainMP = country.domainMP ?? `${MP}.${country.domain}`;
    return ({
      id: country.id,
      name: country.name,
      domainML,
      domainMP,
      domain: platformID === 'MP' ? domainMP : domainML,
    });
  }
}

module.exports = SitesUtils;
