const React = require('react');
const PropTypes = require('prop-types');

const ReactCountryFlag = require('react-country-flag').default;

const SiteOption = (props) => {
  const { data } = props;

  return (
    <div>
      <span className="flag"><ReactCountryFlag countryCode={data.id} /></span>
      <span className="site-data name">{data.name}</span>
    </div>
  );
};

SiteOption.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

module.exports = SiteOption;
