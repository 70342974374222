/**
 * Styles
 */
require('../../pages/crossite/styles.scss');

/**
 * Module dependencies
 */
const React = require('react');
const hydrate = require('nordic/hydrate');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const CrossiteView = require('../../pages/crossite/view');

/**
  * Get server state
  */
const { data, platformID, translations } = window.__PRELOADED_STATE__;


/**
  * i18n
  */
const i18n = new I18n({ translations });

/**
 * Mount CrossiteView on client
 */
hydrate(
  <I18nProvider i18n={i18n}>
    <CrossiteView
      platformID={platformID}
      data={data}
    />
  </I18nProvider>,
  document.getElementById('root-app'),
);
